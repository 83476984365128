<template>
    <div class="cgv container text-justify font-weight-normal">
        <h1>Données personnelles</h1>

        <h4>Qu’est-ce qu’un cookie ? A quoi sert-il ?</h4>

        <p>
            Il s'agit d'un fichier texte qui sauvegarde des informations concernant votre navigation sur le site. Ce fichier est enregistré sur votre ordinateur ou votre périphérique.
            Le cookie ne permet pas de récupérer des informations contenues sur votre disque dur. Il permet de vous reconnaître quand vous naviguez sur notre site et nous permet de vous faciliter son utilisation.
        </p>


        <h4>Les cookies émis sur notre site</h4>

        <p>
            Aucun cookie n'est utilisé sur notre site.
        </p>

        <p>
            Pour en savoir plus sur les cookies nous vous invitons à consulter la page de la CNIL : <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser" target="_blank">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</a>
        </p>
 
        <h4>Les données recueillies sur notre site</h4>

        <p>
            Aucune donnée personnelle n'est enregistrée sur notre site. Lorsque vous envoyez un formulaire de contact, les données que vous avez saisies nous sont transmises par e-mail.
        </p>

        <p>
            Si vous avez une question, n'hésitez pas à nous contacter sur <a href="mailto:hello@olinalab.fr">hello@olinalab.fr</a>.
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "DonneesPersonnelles",
    computed: {
        ...mapGetters({
            locale: 'ui/locale',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .cgv {
        padding-top: 108px;
    }

    a {
        text-decoration: underline;
    }
</style>