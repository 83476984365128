import { render, staticRenderFns } from "./DonneesPersonnelles.vue?vue&type=template&id=6a11df74&scoped=true"
import script from "./DonneesPersonnelles.vue?vue&type=script&lang=js"
export * from "./DonneesPersonnelles.vue?vue&type=script&lang=js"
import style0 from "./DonneesPersonnelles.vue?vue&type=style&index=0&id=6a11df74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a11df74",
  null
  
)

export default component.exports